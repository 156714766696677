.login_container {
    /* background: url('/flood.jpg'); */
    background-color: #66f;
    /* backdrop-filter: blur(32px); */
    /* filter: */
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

div.login_container::before {
    content: '';
    background: conic-gradient(from 30deg, #44f, #88f 20%, #55f 60%, #77f 75%, #44f);
    z-index: 1;
    width: 200%;
    height: 200%;
    position: absolute;
    top: -50%;
    left: -50%;
    overflow: hidden;
    animation: rotate 30s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(1turn);
    }
}

div.login_container::after {
    content: '';
    /* background: url('/flood.jpg'); */
    backdrop-filter: blur(32px);
    /* filter: */
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
}

.login_panel {
    /* visibility: hidden; */
    width: 100%;
    /* height: 300px; */
    margin: 0 auto;
    padding: 32px 0;
    /* position: absolute; */
    /* top: 50%; */
    /* margin-top: 50%; */
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    z-index: 10;
    /* justify-content: space-around; */
}

.login_title {
    font-size: 48px;
    text-align: center;
    color: #333;
    line-height: 64px;
    margin-bottom: 32px;
    text-shadow: 0 2px 2px #999;
}

.login_form input,
.login_btn button {
    line-height: 32px;
    font-size: 16px;
    display: inline-block;
    width: 240px;
    box-sizing: border-box;
    padding: 0 16px;
    margin: 0;
    border: 0;
    border-radius: 8px;
    outline: none;
}

.login_btn button {
    cursor: pointer;
    background-color: #66f;
    color: #fff;
    transition-duration: 0.3s;
}

.login_btn button:hover {
    background-color: #33f;
}

.login_btn button:active {
    background-color: #44f;
}

.login_form,
.login_btn {
    line-height: 48px;
}

.copyright {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    line-height: 32px;
    font-size: 14px;
    z-index: 10;
}