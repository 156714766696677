.TyphoonLegend {
  padding: 10px;
  background-color: white;
  border-radius: inherit;
}

.TyphoonLegendFolded {
  cursor: pointer;
  font-size: 12px;
}

.TyphoonLegend .item {
  vertical-align: baseline;
}

.TyphoonLegend .item span {
  display: inline-block;
  width: 12px;
  height: 12px;
  line-height: 12px;
  border-radius: 100%;
}

.TyphoonLegend button {
  display: block;
  background-color: inherit;
  border: none;
  color: #999999;
  font: inherit;
  width: 100%;
  padding: 0;
  margin-top: 5px;
  cursor: pointer;
}

.TyphoonLegend button:hover,
.TyphoonLegend button:focus {
  color: #666666;
}
