.SearchPanel {
    position: absolute;
    width: 400px;
    top: 72px;
    left: 16px;
    background-color: #fff;
    padding: 8px;
    border-radius: 8px;
    /* line-height: 32px; */
    width: 420px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    /* left: 50%;
    margin-left: -200px; */
    /* background-color: rgba(32, 32, 32, 0.5); */
}

.SearchBar {
    display: flex;
    flex-direction: row;
    height: 32px;
    align-items: stretch;
    width: 100%;
}

.SearchBar input {
    border: 1px solid #ccc;
    border-right: none;
    flex: 1;
    cursor: pointer;
    line-height: 32px;
    font-size: 16px;
    padding: 0 8px;
    outline: none;
    box-sizing: border-box;
}

.SearchBar input::placeholder {
    color: #ccc;
}

.SearchBar input:active,
.SearchBar input:focus,
.SearchBar input:focus-visible {
    border: 1px solid #333;
}

.SearchBar button {
    box-sizing: border-box;
    border: none;
    background-color: #66f;
    color: #fff;
    cursor: pointer;
    line-height: 32px;
    font-size: 16px;
    /* padding: 0 16px; */
    width: 120px;
    outline: none;
}

div.SearchBar :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

div.SearchBar :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.SearchResult {
    padding: 8px 0;
}

.SearchResultItem {
    padding: 4px;
    cursor: pointer;
    color: #666;
    font-size: 14px;
    line-height: 20px;
}

.SearchResultItemSelected {
    background-color: #eee;
}

.SearchResultItem strong {
    font-size: 16px;
    color: #333;
}

.SearchAction {
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.SearchAction div {
    cursor: pointer;
    color: #66f;
}