.App {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Map {
  height: 100%;
  z-index: 0;
  user-select: none;
}

/* .OverlayContainer {
  position: absolute;
  top: 0;
  left: 0;
} */
.Panel {
  position: absolute;
  top: 72px;
  /* width: 200px; */
  left: 16px;
  /* margin-left: -100px; */
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  line-height: 32px;
  width: 420px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

.Panel select {
  font-size: inherit;
  line-height: 25px;
  height: 25px;
}

.pane_hidden {
  visibility: hidden;
}

.pane_dim {
  opacity: 0.3;
}

.loading-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(32, 32, 32, 0.5);
}

.loading-mask div {
  width: 100px;
  height: 30px;
  line-height: 30px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  margin: 10px auto;
}

.TopicSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* padding: 0; */
}

.TopicSelect span {
  display: block;
  /* width: 120px; */
  text-align: center;
  background-color: #eee;
  padding: 0 16px;
  line-height: 32px;
  font-size: 16px;
  /* border-radius: 8px; */
  cursor: pointer;
  flex: 1;
}

.TopicSelect span.selected {
  background-color: #66f;
  color: #fff;
}

div.TopicSelect :first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

div.TopicSelect :last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.TyphoonSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 4px;
}

.TyphoonSelect span {
  display: block;
  /* width: 120px; */
  text-align: center;
  padding: 0 16px;
  line-height: 32px;
  font-size: 16px;
  cursor: pointer;
}

.TyphoonSelect span.selected {
  /* background-color: #eee; */
  /* border-radius: 8px; */
  color: #66f;
}

.TyCurrent {
  cursor: pointer;
}

.TyName {
  font-size: 24px;
  line-height: 48px;
}

.TyList {
  max-height: 50vh;
  overflow-y: auto;
}

.TyItem {
  line-height: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CellRight {
  text-align: right;
  /* width: 23%; */
}

.floodTable tbody {
  display: block;
  max-height: 50vh;
  overflow-y: auto;
}

.floodTable thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  cursor: pointer;
}

/* .floodTable thead {
  width: calc(100% - 1em)
} */

/* .floodTable table {
  width: 400px;
} */
.Menu {
  position: absolute;
  width: 450px;
  top: 16px;
  left: 16px;
  /* background-color: #fff; */
  /* padding: 8px; */
  /* border-radius: 8px; */
  /* line-height: 32px; */
  /* width: 420px; */
  /* box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65); */
  /* left: 50%;
  margin-left: -200px; */
  /* background-color: rgba(32, 32, 32, 0.5); */
  display: flex;
  flex-direction: row;
}

.Menu .Item {
  background-color: #fff;
  border-radius: 8px;
  line-height: 32px;
  margin-right: 16px;
  /* width: 80px; */
  /* width: 420px; */
  /* box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65); */
  /* left: 50%;
  margin-left: -200px; */
  /* background-color: rgba(32, 32, 32, 0.5); */
  cursor: pointer;
  display: block;
  /* width: 120px; */
  text-align: center;
  padding: 4px 16px;
  line-height: 32px;
  font-size: 16px;
  /* border-radius: 8px; */
  flex: 1;
}

.Menu .Item:hover {
  background-color: rgb(239, 239, 255);
}

.Menu .Item.selected {
  background-color: #66f;
  color: #fff;
}