.CoordinatesControl {
  background-color: rgba(255, 255, 255, 0.5);
  line-height: 20px;
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Courier New", Courier, monospace;
}

.CoordinatesControl.cursor {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.7);
}
